import { createSlice } from '@reduxjs/toolkit';

export const barrierSlice = createSlice({
    name: 'barrier',
    initialState: {
        barrierList: [],
        barrier: {}
    },
    reducers: {
        setBarrierList: (state, action) => {
            return { ...state, barrierList: [...action.payload] };
        },

        setBarrier: (state, action) => {
            return { ...state, barrier: action.payload };
        },

        editBarrier: (state, action) => {
            const barrierList = state.barrierList.map(item => {
                if (item.id === action.payload.id) {
                    item = action.payload;
                }
                return item;
            });
            return { ...state, barrierList: [...barrierList] };
        },
    }
});

export const { setBarrierList, editBarrier, setBarrier} = barrierSlice.actions;

export default barrierSlice.reducer;