import { React, useState } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { GetOperatorList } from '../../../Services/operator';
import { EditUser } from '../../../Services/user'
import { useDispatch } from 'react-redux';
import Loader from '../../../Global/loader'
import './Index.scss'

const Edit = (data) => {
    const [loading, setLoading] = useState(true);
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [firstName, setFirstName] = useState(data.data.firstName);
    const [lastName, setLastName] = useState(data.data.lastName);
    const [email, setEmail] = useState(data.data.email);
    const [id] = useState(data.data.id);
    const dispatch = useDispatch();

    const handleShow = async () => {
        setLoading(true);
        setShow(true);
        setLoading(false);
    }
    const handleClose = () => {
        setFirstName(data.data.firstName);
        setLastName(data.data.lastName);
        setShow(false);
    }

    const handleSubmit = async (event) => {
        await setLoading(true);
        event.preventDefault();
        setValidated(true);

        try{
            await EditUser(dispatch, { id, firstName, lastName });
            await GetOperatorList(dispatch)
        }
        catch{
            setValidated(false)
        }

        await setLoading(false);
        await setShow(false);
    }

    return (
        <>
            <Button variant="flat" className="btn" bsPrefix="edit" onClick={handleShow}>
                Promijeni
            </Button>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="add-modal"
            centered
            size="lg"
        >
        {!loading ?
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Izmjeni Operatera</Modal.Title>
                </Modal.Header>

                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="operators_container">
                            <Row style={{justifyContent: "center"}}>
                                <Col sm={12} md={6}>
                                    <Form.Group as={Row} className="mb-3" controlId="fromOperatorsFirstName">
                                        <Form.Label column sm="4">
                                            IME
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control
                                                type="text"
                                                defaultValue={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid" className="input-error">
                                                Ime ne smije biti prazno!
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="formOperatorsEmail">
                                        <Form.Label column sm="4">
                                            EMAIL
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control
                                                type="text"
                                                defaultValue={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid" className="input-error">
                                                Email ne smije biti prazan!
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col sm={12} md={6}>
                                    <Form.Group as={Row} className="mb-3" controlId="formOperatorsLastName">
                                        <Form.Label column sm="4">
                                            PREZIME
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control
                                                type="text"
                                                defaultValue={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid" className="input-error">
                                                Prezime ne smije biti prazno!
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="flat" className="btn" bsPrefix="edit" type="submit">
                            Spremi
                        </Button>
                        <Button  variant="flat" className="btn" bsPrefix="delete" onClick={handleClose}>
                            Otkaži
                        </Button>
                    </Modal.Footer>
                </Form>
            </>
            :
            <div className="loader-container ">
                <Loader />
             </div>
            }
        </Modal>
        </>
    );
};

export default Edit;