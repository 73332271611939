import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';
import Loader from '../../../Global/loader'
import List from './List'
import { GetTransactionList } from '../../../Services/reservation';
import { useHistory } from 'react-router-dom';

const LocationTransactions = ({data}) => {
    const history = useHistory()
    const { page, size, sort_by, sort_direction, from_date, to_date } = useSelector(state => state.locationSlice);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch(); 

    useEffect(() => {        
        async function myFunction() {
            let url = history.location.pathname
            let urldivided = url.split("/");
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            let formatTime1 = await new Date(from_date - tzoffset).toISOString();
            let formatTime2 = await new Date(to_date).toISOString();
            await GetTransactionList(dispatch, {id: urldivided[2]},
                {
                    size: size,
                    page: page,
                    sortBy: sort_by,
                    sortDirection: sort_direction,
                    from: formatTime1,
                    to: formatTime2
                });       
           
           await setLoading(false);          
        }
        myFunction(); // eslint-disable-next-line
    }, []);

    return (
        <>
        {!loading ?
            <List data={data} />
        :
            <div className="operators_container">
                <Row style={{justifyContent: "center", paddingTop: "10%"}}>
                    <Loader />
                </Row>
            </div>
        }
        </>
    )
};

export default LocationTransactions;