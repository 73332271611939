import {
    setOperatorList
} from '../app/operatorSlice';
import * as axios from 'axios';
import { logout } from '../app/authenticationSlice';
import { toast } from 'react-toastify';
import resolve from './../Global/resolver'

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/operators`,
})

axiosInstance.interceptors.request.use((config) => {
    config.headers = { authorization: 'Bearer ' + window.sessionStorage.getItem('token') };
    return config;
});

export const GetOperatorList = async (dispatch, show) => {
    await axios.get(process.env.REACT_APP_BASE_URL + "/operators",
        {
            headers:{ 'Authorization': 'Bearer ' + window.sessionStorage.getItem('token')},
            params: { "page": 0, "size": 1000, "sort": "email,asc", "show": show }
        }
    )
    .then((data) => {
        if(data.data.hasOwnProperty("_embedded")){
            const list = data.data._embedded.userAccountResources
            dispatch(setOperatorList(list));     
        }
        else{
            dispatch(setOperatorList([]));  
        }
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded         
            if(error.response.status === 401){
                dispatch(logout(true));
            }
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        }
        else{
            dispatch(toast.error('Greška prilikom dohvata operatera!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }));
        }
    })
}

export const CreateOperator = async (dispatch,payload) => {
    const data = await axiosInstance.post('/', payload)
        .then(data => {
            toast.success('Operater uspješno kreiran!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }); 
            return true
        })
        .catch((error) => {
            if (error.response) {
                // Request made and server responded         
                if(error.response.status === 401){
                    dispatch(logout(true))
                }               
                else if(error.response.status === 409){
                    dispatch(
                        toast.error('Email postoji, molim odaberite drugu email adresu!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    )
                }
            } else if (error.request) {
                console.log(error.request)
            }
            else{
                dispatch(
                    toast.error('Greška prilikom kreiranja operatera!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                )
            }
            return false
        });
        
    return data;
}

export const EditOperator = async (dispatch, payload) => {
    const data = await axiosInstance.post('/', payload)
        .then(data => {
            toast.success('Operater uspješno izmjenjen!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }); 
            return true
        })
        .catch((error) => {
            console.log(error)
            if (error.response) {
                // Request made and server responded         
                if(error.response.status === 401){
                    dispatch(logout(true))
                }               
            } else if (error.request) {
                console.log(error.request)
            }
            else{
                dispatch(
                    toast.error('Greška prilikom izmjene operatera!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                )
            }
            return false
        });
        
    return data;
}

export default async function PasswordReset (dispatch,payload) {
    return await resolve( axios.post(process.env.REACT_APP_BASE_URL + "/password-reset/", payload)
        .then(data => {
            toast.success('Lozinka uspješno resetirana!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }); 

            return data;
        })
        .catch((error) => {
            return error.response.data;
        })
    );
}

export const ResetPasswordByEmail = async (dispatch,payload) => {
    const data = await axios.post(process.env.REACT_APP_BASE_URL + "/password-reset/via-mail?email=" + payload,
    {
        headers:{ 'Authorization': 'Bearer ' + window.sessionStorage.getItem('token')}     
    }
    )
        .then(data => {
            toast.success('Email za reset lozinke uspješno poslan!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }); 
            return true
        })
        .catch((error) => {
            if (error.response) {
                // Request made and server responded         
                if(error.response.status === 401){
                    dispatch(logout(true))
                }               
                else if(error.response.status === 409){
                    dispatch(
                        toast.error('Email postoji, molim odaberite drugu email adresu!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    )
                }
            } else if (error.request) {
                console.log(error.request)
            }
            else{
                dispatch(
                    toast.error('Greška prilikom slanja emaila za reset lozinke!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                )
            }
            return false
        });
    return data;
}