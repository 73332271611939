import { configureStore } from '@reduxjs/toolkit';
import authenticationSlice from './authenticationSlice';
import operatorSlice from './operatorSlice';
import providerSlice from './providerSlice';
import userSlice from './userSlice';
import locationSlice from './locationSlice';
import barrierSlice from './barrierSlice';
import ruleSlice from './ruleSlice';
import transactionSlice from './transactionSlice'

export default configureStore({
  reducer: {
    authenticationSlice: authenticationSlice,
    providerSlice: providerSlice,
    operatorSlice: operatorSlice,
    userSlice: userSlice,
    locationSlice: locationSlice,
    barrierSlice: barrierSlice,
    ruleSlice: ruleSlice,
    transactionSlice: transactionSlice
  },
 middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})//.concat(ToastMiddleware)
});
