import { React, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { EditProvider } from '../../../Services/provider';
import Loader from '../../../Global/loader'
import './Index.scss'

export default function ProviderList ({ data }) { 
    const dispatch = useDispatch();
    const  { user } = useSelector(state => state.authenticationSlice);
    const [loading, setLoading] = useState(false)
    const [id] = useState(data[0].id);
    const [name] = useState(data[0].name);
    const [pin, setPin] = useState(data[0].pin)
    const [address, setAddress] = useState(data[0].address);
    const [contactEmail, setContactEmail] = useState(data[0].contactEmail);
    const [contactPhone, setContactPhone] = useState(data[0].contactPhone);
    const [postalCode, setPostalCode] = useState(data[0].postalCode);
    const [county, setCounty] = useState(data[0].county);
    const [operatorPin, setOperatorPin] = useState(data[0].operatorPin)
    const [vatRate, setVatRate] = useState(data[0].vatRate);
    const [businessSpace, setBusinessSpace] = useState(data[0].businessSpace)
    const [paymentTerminal, setPaymentTerminal] = useState(data[0].paymentTerminal)
    const [receiptOperator, setReceiptOperator ] = useState(data[0].receiptOperator)
    const [receiptLiquidator , setReceiptLiquidator] = useState(data[0].receiptLiquidator)

    const submitEdit = async () => {
        await setLoading(true);  
        await EditProvider(dispatch, 
            id,
            {
                name, 
                pin, 
                address, 
                contactPhone,
                contactEmail,
                postalCode,
                county,
                receiptLiquidator,
                receiptOperator,
                paymentTerminal,
                businessSpace,
                vatRate,
                operatorPin
            } 
        );
       
        await setLoading(false);  
    }

    return (
        <div className="operators_container">
            {!loading ?
            <>
            <Row style={{justifyContent: "center"}}>
                <Col sm={12} md={6} xl={4} xxl={4}>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextFullName">
                            <Form.Label column sm="4">
                                PUNI NAZIV
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control readOnly defaultValue={name} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label column sm="4">
                                EMAIL
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control 
                                    type="text" 
                                    defaultValue={contactEmail}                                     
                                    onChange={(e) => setContactEmail(e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextTelefon">
                            <Form.Label column sm="4">
                                TELEFON
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control 
                                    type="text" 
                                    defaultValue={contactPhone} 
                                    onChange={(e) => setContactPhone(e.target.value)}                                
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">
                                POŠTANSKI BROJ
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control type="text"
                                    defaultValue={postalCode} 
                                    onChange={(e) => setPostalCode(e.target.value)}       
                                />
                            </Col>
                        </Form.Group>

                        {user.role.id === 1 &&
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextTelefon">
                                <Form.Label column sm="4">
                                    POREZ
                                </Form.Label>
                                <Col sm="6">
                                    <Form.Control type="number"
                                        defaultValue={vatRate} 
                                        onChange={(e) => setVatRate(e.target.value)}   
                                        min={0}    
                                    />
                                </Col>
                            </Form.Group>
                        }

                        {user.role.id === 1 &&
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextTelefon">
                                <Form.Label column sm="4">
                                    POSLOVNI PROSTOR
                                </Form.Label>
                                <Col sm="6">
                                    <Form.Control type="text"
                                        defaultValue={businessSpace} 
                                        onChange={(e) => setBusinessSpace(e.target.value)}   
                                        min={0}    
                                    />
                                </Col>
                            </Form.Group>
                        }

                        {user.role.id === 1 &&
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextTelefon">
                                <Form.Label column sm="4">
                                    LIKVIDATOR
                                </Form.Label>
                                <Col sm="6">
                                    <Form.Control type="text"
                                        defaultValue={receiptLiquidator} 
                                        onChange={(e) => setReceiptLiquidator(e.target.value)}   
                                        min={0}    
                                    />
                                </Col>
                            </Form.Group>
                        }
                    </Form>
                </Col>

                <Col sm={12} md={6} xl={4} xxl={4}>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextOib">
                            <Form.Label column sm="4">
                                OIB
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control 
                                    type="text" 
                                    defaultValue={pin} 
                                    onChange={(e) => setPin(e.target.value)}         
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextAdress">
                            <Form.Label column sm="4">
                                ADRESA
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control 
                                    type="text" 
                                    defaultValue={address} 
                                    onChange={(e) => setAddress(e.target.value)}         
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextTelefon">
                            <Form.Label column sm="4">
                                MJESTO
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control type="text"
                                    defaultValue={county} 
                                    onChange={(e) => setCounty(e.target.value)}       
                                />
                            </Col>
                        </Form.Group>

                        {user.role.id === 1 &&
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextTelefon">
                                <Form.Label column sm="4">
                                    OIB OPERATORA
                                </Form.Label>
                                <Col sm="6">
                                    <Form.Control type="text"
                                        defaultValue={operatorPin} 
                                        onChange={(e) => setOperatorPin(e.target.value)}       
                                    />
                                </Col>
                            </Form.Group>
                        }
                        {user.role.id === 1 &&
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextTelefon">
                                <Form.Label column sm="4">
                                    TERMINAL
                                </Form.Label>
                                <Col sm="6">
                                    <Form.Control type="text"
                                        defaultValue={paymentTerminal} 
                                        onChange={(e) => setPaymentTerminal(e.target.value)}       
                                    />
                                </Col>
                            </Form.Group>
                        }
                        {user.role.id === 1 &&
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextTelefon">
                                <Form.Label column sm="4">
                                    OZNAKA OPERATORA
                                </Form.Label>
                                <Col sm="6">
                                    <Form.Control type="text"
                                        defaultValue={receiptOperator} 
                                        onChange={(e) => setReceiptOperator(e.target.value)}       
                                    />
                                </Col>
                            </Form.Group>
                        }
                    </Form>
                </Col>
            </Row>

            <Row style={{justifyContent: "center", textAlign: "center"}}>
                <Col >
                    <Button onClick={() => submitEdit()}>
                        Promijeni                    
                    </Button>
                </Col>
            </Row>   
            </>
        :
            <Row style={{justifyContent: "center", textAlign: "center", paddingTop:"10%"}}>
                <Loader />
            </Row>
        }       
        </div>
    )
};