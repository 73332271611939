import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Tabs, Tab } from "react-bootstrap";
import Loader from "../../../Global/loader";
import { GetTransactionByUserList } from "../../../Services/transaction";
import List from "./List";
import { useParams } from "react-router-dom";
import { GetUser } from "../../../Services/user";

const Transaction = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { page, size, sort_by, sort_direction, from_date, to_date, search } =
        useSelector((state) => state.transactionSlice);
    const { oneUser } = useSelector((state) => state.userSlice);

    useEffect(() => {
        async function getDate() {
            var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
            let formatTime1 = await new Date(
                from_date - tzoffset
            ).toISOString();
            let formatTime2 = await new Date(to_date - tzoffset).toISOString();

            if (!oneUser.hasOwnProperty("id")) {
                await GetUser(dispatch, id);
            }
            if (!oneUser.hasOwnProperty("id")) {
                await GetTransactionByUserList(dispatch, oneUser, {
                    size: size,
                    page: page,
                    search: search,
                    sortBy: sort_by,
                    sortDirection: sort_direction,
                    from: formatTime1,
                    to: formatTime2,
                });
            }
            await setLoading(false);
        }
        getDate(); // eslint-disable-next-line
    }, []);

    return !loading ? (
        <div id="tabs">
            <div className="inner-tabs">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={1}
                    className="inner-line location-main-tab"
                    style={{ height: "50px" }}
                >
                    <Tab
                        eventKey={1}
                        title={oneUser.firstName + " " + oneUser.lastName}
                    ></Tab>
                </Tabs>
                {!loading ? (
                    <List />
                ) : (
                    <div className="operators_container">
                        <Row
                            style={{
                                justifyContent: "center",
                                paddingTop: "10%",
                            }}
                        >
                            <Loader />
                        </Row>
                    </div>
                )}
            </div>
        </div>
    ) : (
        <div className="operators_container">
            <Row style={{ justifyContent: "center", paddingTop: "10%" }}>
                <Loader />
            </Row>
        </div>
    );
};

export default Transaction;
