import React from 'react'
import { FaClipboardList, FaUserAlt } from 'react-icons/fa'
import { BiCurrentLocation } from 'react-icons/bi'

export const SidebarData =[
    {
        title: "Organizacija",
        icon: <FaClipboardList size={24}/>,
        link: "/organisations"
    },
    {
        title: "Lokacije",
        icon: <BiCurrentLocation size={24}/>,
        link: "/locations"
    },
    {
        title: "Korisnici",
        icon: <FaUserAlt size={24}/>,
        link: "/users"
    }
]