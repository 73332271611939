import {
    setUserList, setTotalElements, editUser, setOneUser
} from '../app/userSlice';
import * as axios from 'axios';
import { logout } from '../app/authenticationSlice';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/users`,
})

axiosInstance.interceptors.request.use((config) => {
    config.headers = { authorization: 'Bearer ' + window.sessionStorage.getItem('token') };
    return config;
});

export const GetUserList = async (dispatch, show) => {
    await axios.get(process.env.REACT_APP_BASE_URL + "/users",
        {
            headers:{ 'Authorization': 'Bearer ' + window.sessionStorage.getItem('token')},
            params: { "page": 0, "size": 1000, "sort": "email,asc", "show": show }
        }
    )
    .then((data) => {
        if(data.data.page.totalElements > 0){
            dispatch(setTotalElements(data.data.page.totalElements))
            const list = data.data._embedded.userAccountResources     
            dispatch(setUserList(list));
        }
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded         
            if(error.response.status === 401){
                dispatch(logout(true));
            }
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        }
        else{
            dispatch(toast.error('Greška prilikom dohvata korisnika!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }));
        }
    })
}

export const GetUser = async (dispatch, id) => {
    await axios.get(process.env.REACT_APP_BASE_URL + "/users/" + id,
        {
            headers:{ 'Authorization': 'Bearer ' + window.sessionStorage.getItem('token')}
        }
    )
    .then((data) => {
        dispatch(setOneUser(data.data));
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded         
            if(error.response.status === 401){
                dispatch(logout(true));
            }
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        }
        else{
            dispatch(toast.error('Greška prilikom dohvata korisnika!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }));
        }
    })
}


export const CreateUser = async (dispatch,payload) => {
    const data = await axiosInstance.post('/', payload)
        .then(data => {
            toast.success('Korisnik uspješno izmjenjen!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }); 
            return true
        })
        .catch((error) => {
            if (error.response) {
                // Request made and server responded         
                if(error.response.status === 401){
                    dispatch(logout(true))
                }               
                else if(error.response.status === 409){
                    dispatch(
                        toast.error('Email postoji, molim odaberite drugu email adresu!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    )
                }
            } else if (error.request) {
                console.log(error.request)
            }
            else{
                dispatch(
                    toast.error('Greška prilikom izmjene korisnika!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                )
            }
            return false
        });
        
    return data;
}

export const EditUser = async (dispatch, payload) => {
    console.log(payload)
    const data = await axiosInstance.put('/' + payload.id , payload)
        .then(data => {
         dispatch(editUser(payload));
            toast.success('korisnik uspješno izmjenjen!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }); 
            return true
        })
        .catch((error) => {
            console.log(error)
            if (error.response) {
                // Request made and server responded         
                if(error.response.status === 401){
                    dispatch(logout(true))
                }               
            } else if (error.request) {
                console.log(error.request)
            }
            else{
                dispatch(
                    toast.error('Greška prilikom izmjene korisnika!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                )
            }
            return false
        });
        
    return data;
}


export const ActivityUser = async (dispatch, payload) => {
    const data = await axiosInstance.patch('/' + payload.id + "/activity",  {active: !payload.isActive})
        .then(data => {
            toast.success('Aktivnost Operatora izmjenjena!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }); 
            return true
        })
        .catch((error) => {
            console.log(error)
            if (error.response) {
                // Request made and server responded         
                if(error.response.status === 401){
                    dispatch(logout(true))
                }               
            } else if (error.request) {
                console.log(error.request)
            }
            else{
                dispatch(
                    toast.error('Greška prilikom izmjene aktivnosti operatera!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                )
            }
            return false
        });
        
    return data;
}