import { createSlice } from '@reduxjs/toolkit';

var date = new Date();
var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);


export const providerSlice = createSlice({
    name: 'provider',
    initialState: {
        providerList: [],
        certificate: '',

        transactionList: [],
        totalElements: 0,
        transaction: {},
        page: 1,
        size: 10,
        sort_by: 1,
        sort_direction: 1,
        from_date: firstDay,
        to_date: new Date(),
        search: '',
    },
    
    reducers: {
        setProviderList: (state, action) => {
            return { ...state, providerList: [...action.payload] };
        },

        setProviderCertificate: (state, action) => {
            return { ...state, certificate: action.payload };
        },

        editProvider: (state, action) => {
            const providerList = state.providerList.map(item => {
                if (item.id === action.payload.id) {
                    item = action.payload;
                }
                return item;
            });
            return { ...state, providerList: [...providerList] };
        },

        setTransactionList: (state, action) => {
            return { ...state, transactionList: [...action.payload] };
        },

        setTransaction: (state, action) => {
            return { ...state, transaction: action.payload };
        },

        setTotalElements: (state, action) => {
            return { ...state, totalElements: action.payload };
        },

        setListPageNumber: (state, action) => {
            return { 
                ...state,
                page: action.payload,
            };
        },

        setListPageSize: (state, action) => {
            return { 
                ...state,
                size: action.payload,
            };
        },

        setListSortBy: (state, action) => {
            return { 
                ...state,
                sort_by: action.payload,
            };
        },

        setListSortDirection: (state, action) => {
            return { 
                ...state,
                sort_direction: action.payload,
            };
        },

        setFromDateList:  (state, action) => {
            return { 
                ...state,
                from_date: action.payload,
            };
        },

        setToDateList:  (state, action) => {
            return { 
                ...state,
                to_date: action.payload,
            };
        },

        setListSearch: (state, action) => {
            console.log(action.payload)
            return { 
                ...state,
                search: action.payload,
            };
        },
    }
});

export const { setProviderList, editProvider, setTransactionList, editTransaction, setTransaction, setTotalElements, 
    setListPageNumber, setListPageSize, setListSortBy, setListSortDirection, setFromDateList, setToDateList, setListSearch,
    setProviderCertificate } = providerSlice.actions;

export default providerSlice.reducer;