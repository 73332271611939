import { React } from 'react';
import './index.scss';
import TopBar from './TopBar'
import Sidebar from './Sidebar'

const LayoutMain = () => {
    return  <div style={{height: "inherit"}}>
        <TopBar />
        <div style={{height: "calc(100% - 50px)", display: "flex", width: "inherit"}}>
            <Sidebar />
        </div>
    </div>
}

export default LayoutMain;