import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import Loader from "../../../Global/loader";
import Edit from "./edit";
import "./Index.scss";

const Location = () => {
    const [loading, setLoading] = useState(true);
    const { location } = useSelector((state) => state.locationSlice); //get from state

    useEffect(() => {
        setLoading(false);
    }, [loading]);

    return (
        <>
            {!loading ? (
                <Edit data={location} />
            ) : (
                <div className="operators_container">
                    <Row
                        style={{ justifyContent: "center", paddingTop: "10%" }}
                    >
                        <Loader />
                    </Row>
                </div>
            )}
        </>
    );
};

export default Location;
