import { React, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import { EditLocation } from "../../../Services/location";
import Loader from "../../../Global/loader";
import "./Index.scss";

export default function ProviderList({ data }) {
    const [loading, setLoading] = useState(false);
    const [id] = useState(data.id);
    const [name, setName] = useState(data.name);
    const [providerId] = useState(1);
    const [latitude, setLatitude] = useState(data.latitude);
    const [longitude, setLongitude] = useState(data.longitude);
    const [address, setAddress] = useState(data.address);
    const [city, setCity] = useState(data.city);
    const [totalSpaces, setTotalSpaces] = useState(data.totalSpaces);
    const [price, setPrice] = useState(data.price);
    const [note, setNote] = useState(data.note);
    const dispatch = useDispatch();

    const submitEdit = async () => {
        await setLoading((s) => !s);
        await EditLocation(dispatch, id, {
            name,
            providerId,
            address,
            latitude,
            longitude,
            city,
            totalSpaces,
            price,
            note,
            parkingSpacesId: data.parkingSpacesId,
        });

        await setLoading((s) => !s);
    };

    return (
        <div className="operators_container">
            {!loading ? (
                <>
                    <Row style={{ justifyContent: "center" }}>
                        <Col sm={12} md={6} xl={4} xxl={4}>
                            <Form>
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formLocationName"
                                >
                                    <Form.Label column sm="4">
                                        IME
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            type="text"
                                            defaultValue={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formLocationAddress"
                                >
                                    <Form.Label column sm="4">
                                        ADRESA
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            type="text"
                                            defaultValue={address}
                                            onChange={(e) =>
                                                setAddress(e.target.value)
                                            }
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formLocationCity"
                                >
                                    <Form.Label column sm="4">
                                        GRAD
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            type="text"
                                            defaultValue={city}
                                            onChange={(e) =>
                                                setCity(e.target.value)
                                            }
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formLocationTotalSpaces"
                                >
                                    <Form.Label column sm="4">
                                        BROJ MJESTA
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            type="number"
                                            min={0}
                                            defaultValue={totalSpaces}
                                            onChange={(e) =>
                                                setTotalSpaces(e.target.value)
                                            }
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className="input-error"
                                        >
                                            Broj mjesta ne smije biti prazan!
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>

                        <Col sm={12} md={6} xl={4} xxl={4}>
                            <Form>
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formLocationLongitude"
                                >
                                    <Form.Label column sm="4">
                                        LATITUDE
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            type="number"
                                            min={-90}
                                            max={90}
                                            defaultValue={latitude}
                                            onChange={(e) =>
                                                setLatitude(e.target.value)
                                            }
                                            required
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className="input-error"
                                        >
                                            Latitude mora biti između -90° i 90°
                                            !
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formLocationLatitude"
                                >
                                    <Form.Label column sm="4">
                                        LONGITUDE
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            type="number"
                                            min={-180}
                                            max={180}
                                            defaultValue={longitude}
                                            onChange={(e) =>
                                                setLongitude(e.target.value)
                                            }
                                            required
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className="input-error"
                                        >
                                            Longitude mora biti izmedu -180° i
                                            180° !
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formLocationePrice"
                                >
                                    <Form.Label column sm="4">
                                        CIJENA / H
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            type="number"
                                            min={0}
                                            defaultValue={price}
                                            onChange={(e) =>
                                                setPrice(e.target.value)
                                            }
                                            required
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className="input-error"
                                        >
                                            Cijena ne smije biti prazna ili
                                            negativna!
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formLocationePrice"
                                >
                                    <Form.Label column sm="4">
                                        NAPOMENA
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            as="textarea"
                                            defaultValue={note}
                                            onChange={(e) =>
                                                setNote(e.target.value)
                                            }
                                            name="invoice_remark"
                                        />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Col>
                            <Button onClick={() => submitEdit()}>
                                Promijeni
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : (
                <Row
                    style={{
                        justifyContent: "center",
                        textAlign: "center",
                        paddingTop: "10%",
                    }}
                >
                    <Loader />
                </Row>
            )}
        </div>
    );
}
