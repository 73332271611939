import React, { useState, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Loader from '../../../Global/loader'
import moment from 'moment'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { setListPageNumber, setListPageSize, setListSortBy , setListSortDirection, setFromDateList, setToDateList } from '../../../app/transactionSlice';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai';
import { GetTransactionByProviderList } from '../../../Services/transaction';
import { ImCheckmark, ImCross } from 'react-icons/im'
import DatePicker from 'react-datepicker'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import hr from 'date-fns/locale/hr';
import { MdDateRange } from 'react-icons/md'

const OrganizationTransactionList = () => {
    const dispatch = useDispatch();
    const { transactionList, totalElements, page, size, sort_by, sort_direction, from_date, to_date } = useSelector(state => state.providerSlice);
    const [loading, setLoading] = useState(false);    
    const [sizeList, setPage_Size] = useState(size);
    const [pageList, setPage_Number] = useState(page);
    const [sortBy, setSortBy] = useState(sort_by);
    const [sortDirection, setSortDirection] = useState(sort_direction);
    const [timeFrom, setTimeFrom] = useState(from_date);
    const [timeTo, setTimeTo] = useState(to_date);

    const pageChanger = async (event) => {
        console.log(event.target.innerHTML)
        const count = event.target.innerHTML === '&lt;' ? pageList - 1 :  event.target.innerHTML === '&gt;' ? pageList + 1 : Number(event.target.innerHTML) 
        let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        let formatTime1 = await new Date(timeFrom - tzoffset).toISOString();
        let formatTime2 = await new Date(timeTo).toISOString();
        await setLoading(true);
        await setPage_Number(count);
        await dispatch(setListPageNumber(count));
        await GetTransactionByProviderList(dispatch, { size: size, page: count, sortBy: sortBy, sortDirection: sortDirection, from: formatTime1, to: formatTime2 });
        await setLoading(false);
    }

    const onItemsPerPageChange = async (event) => {
        let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        let formatTime1 = await new Date(timeFrom - tzoffset).toISOString();
        let formatTime2 = await new Date(timeTo).toISOString();
        await setLoading(true);
        await setPage_Size(event);
        await dispatch(setListPageSize(event));
        await setPage_Number(1);
        await dispatch(setListPageNumber(1));
        await GetTransactionByProviderList(dispatch, { size: event, page: 1, sortBy: sortBy, sortDirection: sortDirection, from: formatTime1, to: formatTime2 });
        await setLoading(false);
    }

    const toggleSort = async (index) => {
        await setLoading(true);
        let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        let formatTime1 = await new Date(timeFrom - tzoffset).toISOString();
        let formatTime2 = await new Date(timeTo).toISOString();
        if(sortBy === index){
            let direction = sortDirection === 0 ? 1 : 0;
            await dispatch(setListSortDirection(direction));
            await setSortDirection(direction);
            await GetTransactionByProviderList(dispatch, { size: size, page: 1, sortBy: index, sortDirection: direction, from: formatTime1, to: formatTime2});
        }
        else{
            await setSortBy(index);
            await dispatch(setListSortBy(index));
            await dispatch(setListSortDirection(0));
            await setSortDirection(0)
            await GetTransactionByProviderList(dispatch, {size: size, page: 1, sortBy: index, sortDirection: 0, from: formatTime1, to: formatTime2});
        }
        await setLoading(false);
    }

    const handleDateChange = async (e, field) => {
        await setLoading(true);
        e = e === null ? "" : e

        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var formatTime1;
        var formatTime2;

        await setPage_Number(1);
        await dispatch(setListPageNumber(1));

        if(field === "time1List"){
            await setTimeFrom(e);
            await dispatch(setFromDateList(e));        
            formatTime1 = await new Date(e - tzoffset).toISOString();
            formatTime2 = await new Date(timeTo - tzoffset).toISOString();
            if(e > timeTo){
                let formDate = new Date(e);
                console.log(formDate)
                formDate.setHours(23);
                formDate.setMinutes(59);
                formDate.setSeconds(59);
                await setTimeTo(formDate);
                await dispatch(setToDateList(formDate));
                formatTime2 = await new Date(formDate - tzoffset).toISOString();
                console.log(timeTo)
            }
        }
        else{
            let formDate = new Date(e);
            formDate.setUTCHours(22,59,59,999);
            await setTimeTo(formDate);
            await dispatch(setToDateList(formDate));
            formatTime1 = await new Date(timeFrom - tzoffset).toISOString();
            formatTime2 = await new Date(formDate - tzoffset).toISOString();
            if(e < timeFrom){
                let toDate = new Date(e);
                toDate.setUTCHours(1,0,0);
                console.log(toDate)
                await setTimeFrom(toDate);
                await dispatch(setFromDateList(toDate));
                formatTime1 = await new Date(toDate).toISOString();
                console.log(timeTo)
            }
            
        }
        
        await GetTransactionByProviderList(dispatch, 
            {
                size: size,
                page: page,
                sortBy: sortBy,
                sortDirection: sortDirection,
                from: formatTime1,
                to: formatTime2
            });

        await setLoading(false);
    }

     const columns = [
        {
            dataField: 'userAccount',
            text: 'KORISNIK',
            style: {wordWrap: "break-word"},
            formatter: (cellContent, row) => {    
                return (                    
                    <span>
                        {cellContent.email}
                    </span>
                );
            },  
        },
        {
        dataField: 'itemName',
        text: 'NAZIV',
        style: {wordWrap: "break-word"},
        headerEvents: {
            onClick: async (e, column, columnIndex) => {
                console.log("HEADER CLICK")
                await setLoading(true);
                await toggleSort(columnIndex);
                await setLoading(false);
            }
        },
        sort: true,
        sortCaret: (order, column) => { 
            if (sortBy !== 1) return (<span>&nbsp;&nbsp;<AiFillCaretUp color={"white"} /><AiFillCaretDown color={"white"} /></span>);
            else if (sortDirection === 1) return (<span>&nbsp;&nbsp; <AiFillCaretDown color={"white"} /></span>);
            return (<span>&nbsp;&nbsp;<AiFillCaretUp color={"white"} /></span>);          
        }     
    },
    {
        dataField: 'creation',
        text: 'KREIRAN',
        style: {wordWrap: "break-word"},
        formatter: (cellContent, row) => {    
            return (                    
                <span>
                    {moment(cellContent).format("DD.MM.YYYY")}
                </span>
            );
        },
        headerEvents: {
            onClick: async (e, column, columnIndex) => {
                await setLoading(true);
                await toggleSort(columnIndex);
                await setLoading(false);
            }
        },
        sort: true,
        sortCaret: (order, column) => { 
            if (sortBy !== 2) return (<span>&nbsp;&nbsp;<AiFillCaretUp color={"white"} /><AiFillCaretDown color={"white"} /></span>);
            else if (sortDirection === 1) return (<span>&nbsp;&nbsp; <AiFillCaretDown color={"white"} /></span>);
            return (<span>&nbsp;&nbsp;<AiFillCaretUp color={"white"} /></span>);          
        }        
    },
    {
        dataField: 'provider',
        text: 'LOKACIJA',
        style: {wordWrap: "break-word"},
        formatter: (cellContent, row) => {    
            return (                    
                <span>
                    {cellContent.address}
                </span>
            );
        }
    }
    ,{
        dataField: 'amount',
        text: 'IZNOS',
        style: {wordWrap: "break-word"},
        formatter: (cellContent, row) => {   
            var datum1 = new Date(2022, 12, 31);
            var datum2 = new Date(row.transactionDate); 
            return (                    
                <span>
                    {cellContent} {datum1 < datum2 ? "eur" : "kn"}
                </span>
            );
        },
        headerEvents: {
            onClick: async (e, column, columnIndex) => {
                await setLoading(true);
                await toggleSort(columnIndex);
                await setLoading(false);
            }
        },
        sort: true,
        sortCaret: (order, column) => { 
            if (sortBy !== 4) return (<span>&nbsp;&nbsp;<AiFillCaretUp color={"white"} /><AiFillCaretDown color={"white"} /></span>);
            else if (sortDirection === 1) return (<span>&nbsp;&nbsp; <AiFillCaretDown color={"white"} /></span>);
            return (<span>&nbsp;&nbsp;<AiFillCaretUp color={"white"} /></span>);          
        }       
    },{
        dataField: 'fiscalize',
        text: 'FISKALIZIRANO',
        headerStyle: {wordWrap: "break-word"},
        formatter: (cellContent, row) => {          
        return (
            <div key={row.uuid} style={{wordWrap: "break-word"}}>
                <span>
                    <strong>
                        { cellContent ? <ImCheckmark /> : <ImCross /> }
                    </strong>
                </span>
            </div>
        );
        }
    }
]

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            { from } - { to } of { size } Operatera
        </span>
    );

    const pageButtonRenderer = ({
        page,
        active,
        disabled,
        //title,
        //onPageChange
    }) => {
        const handleClick = async (e) => {      
            e.preventDefault();
            await pageChanger(e)
            //onPageChange(page);
            //setPage(page);
        };
        return (
        <li className={active ? "active page-item" : disabled ? "disabled page-item" : "page-item"} style={{color: 'white'}} key={page}>
            <a href="/" className="page-link" onClick={ handleClick }>{ page }</a>
        </li>
        );
    };

    const sizePerPageOptionRenderer = ({
        text,
        page,
        //onSizePerPageChange
    }) => (
        <li
        key={ text }
        role="presentation"
        className="dropdown-item"
        onMouseDown={ async (e) => {
            e.preventDefault();
            await onItemsPerPageChange(page);
        } }
        >
            <a
                href={() => false}
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'white' } }
            >
                { text }
            </a>
        </li>
    );

    const options = {
        custom: true,
        totalSize: totalElements,
        sizePerPageOptionRenderer,
        paginationTotalRenderer: customTotal,
        prePageText: '<',
        nextPageText: '>',
        withFirstAndLast: false,
        alwaysShowAllBtns: true,
        sizePerPage: sizeList,
        pageButtonRenderer,
        page: pageList
    };

    const onTableChange = (type, newState) => {
        //dummy
      }

    function DatePickerElement({handleDateChange, time, changehook}) {
        const ref = React.createRef()

        const CustomDateInput = forwardRef(({ onClick, value }, ref) => (
            <Button variant="flat" className="btn" bsPrefix="edit" style={{marginRight:"30px", display: "flex"}} onClick={onClick} ref={ref}> 
                <span style={{padding: "0px 5px", alignSelf: "center", fontWeight: "800"}}> {value} </span> <MdDateRange size={24}/> 
            </Button>
        ))
        return (
            <DatePicker
                selected={time}
                onChange={date => handleDateChange(date, changehook)}
                customInput={<CustomDateInput ref={ref} />}
                locale={hr}
                dateFormat="dd.MM.yyyy"
                minDate={new Date("1.1.2022")}
                maxDate={new Date()}
            />
        )
      }

    return (
        <div className="table-design">
            {loading ?
            <div className="rules_container">
                <Row style={{justifyContent: "center", paddingTop: "10%"}}>
                    <Loader />
                </Row>
            </div>
            :   
            totalElements < 1 ?
                <Row style={{textAlign: "center"}} className="under-list-row p-4">
                    <h1>Lista je prazna</h1>
                    <Col sm={2} md={6} xl={6} xxl={6} style={{display: "flex"}} className="ps-4 m-4">
                        <div className="w-fit-content">
                            <DatePickerElement handleDateChange={handleDateChange} time={timeFrom} changehook={"time1List"}/>
                        </div>
                        <div className="w-fit-content">
                            <DatePickerElement handleDateChange={handleDateChange} time={timeTo} changehook={"time2List"}/>
                        </div>
                    </Col>
                </Row> 
           :
                <PaginationProvider
                    pagination={ paginationFactory(options) }
                >
                    {
                        ({
                        paginationProps,
                        paginationTableProps
                        }) => (
                        <div>    
                            {loading ?
                                <div className="rules_container">
                                    <Row style={{justifyContent: "center", paddingTop: "10%"}}>
                                        <Loader />
                                    </Row>
                                </div>
                            :
                            <BootstrapTable
                                remote
                                keyField="id"
                                data={ transactionList }
                                columns={ columns }
                                {...paginationTableProps}  
                                sort={ { dataField: 'id', order: 'desc' } }
                                onTableChange={onTableChange}
                                noDataIndication="Lista je prazna"
                            />
                            }
                            
                            <Row className="under-list-row">
                                <Col sm={2} md={6} xl={6} xxl={6} style={{display: "flex"}} className="ps-4">
                                    <div className="w-fit-content">
                                        <DatePickerElement handleDateChange={handleDateChange} time={timeFrom} changehook={"time1List"}/>
                                    </div>
                                    <div className="w-fit-content">
                                        <DatePickerElement handleDateChange={handleDateChange} time={timeTo} changehook={"time2List"}/>
                                    </div>                        
                                </Col>
                                
                                <Col sm={10} md={6} xl={6} xxl={6} className="pagination-right pe-4">
                                    <PaginationListStandalone
                                    { ...paginationProps }
                                    
                                    />
                                    <span className="react-bootstrap-table-pagination-total">Redova po stranici: </span>
                                    <SizePerPageDropdownStandalone
                                        { ...paginationProps }
                                    />
                                    <PaginationTotalStandalone
                                        { ...paginationProps }                                        
                                    />
                                </Col>
                              
                            </Row>
                        </div>
                        )
                    }
                </PaginationProvider>
                
            }
        </div>
    )
};

export default OrganizationTransactionList;
