import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Row } from "react-bootstrap";
import { GetLocation } from "../../Services/location";
import Loader from "../../Global/loader";
import AddBarier from "./Barriers/Add";
import AddRules from "./Rules/Add";
import General from "./General/Index";
import Barriers from "./Barriers/List";
import Rules from "./Rules/List";
import Transactions from "./Transactions/Index";
import { useParams } from "react-router-dom";

const Location = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { location } = useSelector((state) => state.locationSlice); //get from state
    const [key, setKey] = useState("general");
    const { id } = useParams();

    useEffect(() => {
        async function getData() {
            GetLocation(dispatch, id);
            setLoading(false);
        }
        getData(); // eslint-disable-next-line
    }, []);

    const returnActiveTabList = () => {
        switch (key) {
            case "rules":
                return <AddRules />;
            case "barriers":
                return <AddBarier />;
            default:
                return null;
        }
    };

    return (
        <>
            {loading && (
                <div className="operators_container">
                    <Row
                        style={{ justifyContent: "center", paddingTop: "10%" }}
                    >
                        <Loader />
                    </Row>
                </div>
            )}

            {location.hasOwnProperty("id") && (
                <div id="tabs">
                    <div className="addToSecondTab">
                        {returnActiveTabList()}
                    </div>
                    <div className="inner-tabs">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={location.name}
                            className="inner-line location-main-tab"
                            style={{ height: "50px" }}
                        >
                            <Tab eventKey={location.name} title={location.name}>
                                <Tabs
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="inner-line"
                                    style={{ height: "50px" }}
                                >
                                    <Tab eventKey="general" title="OPĆENITO">
                                        <General />
                                    </Tab>

                                    <Tab eventKey="barriers" title="BARIJERE">
                                        <Barriers />
                                    </Tab>

                                    <Tab eventKey="rules" title="PRAVILA">
                                        <Rules />
                                    </Tab>

                                    <Tab
                                        eventKey="transactions"
                                        title="TRANSAKCIJE"
                                    >
                                        <Transactions />
                                    </Tab>
                                </Tabs>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            )}
        </>
    );
};

export default Location;
