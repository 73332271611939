import { setBarrierList } from "../app/barrierSlice";
import * as axios from "axios";
import { logout } from "../app/authenticationSlice";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/barriers`,
});

axiosInstance.interceptors.request.use((config) => {
    config.headers = {
        authorization: "Bearer " + window.sessionStorage.getItem("token"),
    };
    return config;
});

export const GetBarrierList = async (dispatch, data) => {
    await axiosInstance
        .get(
            process.env.REACT_APP_BASE_URL +
                "/locations/" +
                data.id +
                "/barriers"
        )
        .then((data) => {
            if (typeof data.data._embedded !== "undefined") {
                const list = data.data._embedded.barrierResources;
                dispatch(setBarrierList(list));
            } else {
                dispatch(setBarrierList([]));
            }
        })
        .catch((error) => {
            if (error.response) {
                // Request made and server responded
                if (error.response.status === 401) {
                    dispatch(logout(true));
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                dispatch(
                    toast.error("Greška prilikom dohvata barijera!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
            }
        });
};

export const CreateBarrier = async (dispatch, payload) => {
    const data = await axiosInstance
        .post("", payload)
        .then((data) => {
            toast.success("Barijera uspješno dodana!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return true;
        })
        .catch((error) => {
            if (error.response) {
                // Request made and server responded
                if (error.response.status === 401) {
                    dispatch(logout(true));
                }

                console.log(error.response);
            } else if (error.request) {
                console.log(error.request);
            } else {
                dispatch(
                    toast.error("Greška prilikom kreiranja barijere!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
            }
            return false;
        });

    return data;
};

export const EditBarrier = async (dispatch, id, payload) => {
    const data = await axiosInstance
        .put("/" + id, payload)
        .then((data) => {
            toast.success("Barijera uspješno izmjenjena!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return true;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // Request made and server responded
                if (error.response.status === 401) {
                    dispatch(logout(true));
                }
            } else if (error.request) {
                console.log(error.request);
            } else {
                dispatch(
                    toast.error("Greška prilikom izmjene barijere!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
            }
            return false;
        });

    return data;
};
