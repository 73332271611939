import { React, useState } from "react";
import { SignIn } from "../Services/authentication";
import { useDispatch } from "react-redux";
import Logo from "./../Images/svg/Logo.svg";
import "./SignInPage.scss";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { FaQuoteLeft } from "react-icons/fa";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import ReactLoading from "react-loading";

const SignInPage = () => {
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
        await setLoading(true);
        event.preventDefault();

        await SignIn(dispatch, { email, password });
        setValidated(true);
        await setLoading(false);
    };

    return (
        <Row className="login_row">
            <Col xs={4} className="left_login primary">
                <div className="image_container">
                    <img
                        src={Logo}
                        alt="logo"
                        className="logo"
                        style={{ padding: "40px" }}
                    />
                </div>
                <div className="quote_mark">
                    <div className="quotes">
                        <FaQuoteLeft />
                    </div>
                    <blockquote>
                        Društvo koje predstavlja uređeni visokokvalitetni sustav
                        koji doprinosi svojim tehničkim rješenjima u upravljanju
                        pametnim gradom Šibenikom!
                    </blockquote>
                    <div className="quotes closing_quote">
                        <FaQuoteLeft />
                    </div>
                </div>
            </Col>
            <Col className="right_login" xs={8}>
                <div className="login">
                    {loading ? (
                        <div className="loader-container ">
                            <div className="login__form">
                                <ReactLoading
                                    type={"spin"}
                                    color={"#05367C"}
                                    height={"25%"}
                                    width={"25%"}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <Form
                                className="login__form"
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Form.Group
                                    className="mb-3"
                                    controlId="validationCustomEmail"
                                >
                                    <Form.Label>EMAIL ADRESA</Form.Label>
                                    <InputGroup
                                        className=""
                                        style={{ maring: "0px" }}
                                        hasValidation
                                    >
                                        <Form.Control
                                            type="email"
                                            required
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Email nije ispravan.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group
                                    className="mb-3"
                                    controlId="validationCustomPassword"
                                >
                                    <Form.Label>LOZINKA</Form.Label>
                                    <InputGroup
                                        className=""
                                        style={{ maring: "0px" }}
                                        hasValidation
                                    >
                                        <Form.Control
                                            className="login_password"
                                            type={
                                                isRevealPwd
                                                    ? "text"
                                                    : "password"
                                            }
                                            required
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                        />
                                        <AiOutlineEyeInvisible
                                            className="login_eye"
                                            title={
                                                isRevealPwd
                                                    ? "Hide password"
                                                    : "Show password"
                                            }
                                            onClick={() =>
                                                setIsRevealPwd(
                                                    (prevState) => !prevState
                                                )
                                            }
                                            size={30}
                                            color={
                                                isRevealPwd
                                                    ? "#99A2AA"
                                                    : "black"
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Lozinka ne smije biti prazna.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <br />
                                <Button
                                    variant="primary login_button"
                                    type="submit"
                                >
                                    Login
                                </Button>

                                <br />
                                <br />
                                <div className="info">
                                    <div
                                        className="vl-right"
                                        style={{ textAlign: "center" }}
                                    >
                                        <span>Privatnost</span>
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <span style={{ height: "20px" }}>
                                            Opći uvijeti
                                        </span>
                                    </div>
                                    <div
                                        className="vl-left"
                                        style={{ textAlign: "center" }}
                                    >
                                        <span>Help</span>
                                    </div>
                                </div>
                            </Form>
                        </>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default SignInPage;
